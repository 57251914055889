<template>
  <div>
    <template v-if="selectedValue">
      <app-text-field
        label=" Profissional"
        :value="selectedValue.name"
        readonly
        @click="open()"
        prepend-inner-icon="mdi-magnify"
        @keypress="open()"
        v-bind="$attrs"
        @click:clear="clear()"
        :disabled="disabled"
      />
    </template>
    <template v-else>
      <div id="acc" @click.stop="open()">
        <app-text-field
          label=" Profissional"
          readonly
          @click="open()"
          v-bind="$attrs"
          :disabled="disabled"
          prepend-inner-icon="mdi-magnify"
        />
      </div>
    </template>

    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div>
              <span>Selecionar Profissional </span>
            </div>
          </div>
        </v-card-title>
        <v-card-subtitle>
          <v-text-field
            :loading="isLoading"
            class="mt-4"
            v-model="searchForm.text"
            autofocus
            solo
            flat
            background-color="grey lighten-4"
            prepend-inner-icon="mdi-magnify"
            placeholder="Pesquisar..."
            hide-details
            v-debounce:300ms="search"
          />
        </v-card-subtitle>
        <v-card-text>
          <v-list>
            <v-list-item-group
              active-class="primary--text"
              :multiple="isMultiple"
              v-model="selected"
              return-object
            >
              <template v-for="(row, index) in professionals.data">
                <v-list-item
                  :value="row"
                  :key="index"
                  @click="setSelectedValue(row)"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-avatar>
                      <AppImg :size="40" :src="row.media[0]" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-text="row.name" />
                    </v-list-item-content>
                    <v-list-item-icon v-if="active && isMultiple">
                      <app-icon>done</app-icon>
                    </v-list-item-icon>
                  </template>
                </v-list-item>
                <v-divider :key="'div' + index" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Voltar </v-btn>
          <v-btn v-if="isMultiple" @click="selectedMultiple()" color="primary"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppImg from "@/components/app/sections/AppImg.vue";

export default {
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppImg,
  },
  data() {
    return {
      selected: [],
      professionals: {
        data: [],
      },

      selectedValue: {
        name: null,
      },

      dialog: false,

      isLoading: false,

      searchForm: {
        is_professional: 1,
        status: "active",
      },
    };
  },

  watch: {
    value() {
      this.selectedValue = this.value;
    },
  },

  created() {
    this.selectedValue = this.value;
  },
  computed: {
    isMultiple() {
      return this.multiple;
    },
  },

  methods: {
    async open() {
      await this.search();
      this.selected = [];
      this.dialog = true;
    },

    async search() {
      this.isLoading = true;

      await this.$http.index("user/users", this.searchForm).then((response) => {
        this.professionals = response.users;
      });

      this.isLoading = false;
    },

    getItemText(row) {
      if (typeof this.itemText == "function") {
        return this.itemText(row);
      } else {
        return row[this.itemText];
      }
    },

    setSelectedValue(selectedValue) {
      if (!this.isMultiple) {
        this.selectedValue = selectedValue;

        this.$emit("input", selectedValue);

        this.dialog = false;
      }
    },

    selectedMultiple() {
      this.$emit("input", this.selected);
      this.dialog = false;
    },

    clear() {
      this.selectedValue = null;
      this.$emit("input", null);
    },
  },
};
</script>

<style>
</style>