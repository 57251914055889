<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="1000px"
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <BaseCustomerForm class="py-6" v-model="form" />
      </v-card-text>

      <v-card-actions>
        <app-back-btn @click="dialog = false" />
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
        <app-confirm-btn @click="handleSave()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseCustomerForm from "./BaseCustomerForm.vue";
import { customerAlias } from "@/utils/customerAlias";
export default {
  components: { BaseCustomerForm },
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      form: {},

      baseForm: {
        name: null,
        whatsapp: null,
        email: null,
        cpf: null,
        rg: null,
        value_per_session: null,
        send_billing_message: false,
        send_session_reminder: 1,
        financial_responsible_name: null,
        financial_responsible_whatsapp: null,
        financial_responsible_email: null,
        financial_responsible_cpf: null,
        financial_responsible_rg: null,
        genre: null,
        birth: null,
        nationality: null,
        scholarity: null,
        occupation: null,
        forwarding: null,
        group: {},
        collection_day: null,
        status: "active",
        main_address: {
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city: null,
          state: null,
        },
      },
    };
  },
  computed: {
    isInsert() {
      return !this.form.id;
    },
    title() {
      if (this.isInsert) {
        return `Cadastrar ${customerAlias()}`;
      }
      if (!this.isInsert) {
        return `Atualizar ${customerAlias()}`;
      }
    },
  },

  created() {
    this.reset();
  },

  methods: {
    open(id = null) {
      this.reset();
      if (id) {
        this.showCustomer(id);
      }
      this.dialog = true;
    },

    new(data) {
      this.open();
      this.form = JSON.parse(JSON.stringify(data));
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
    },

    async showCustomer(id) {
      this.$loading.start();
      await this.$http
        .show("customer/customer", id)
        .then((response) => {
          this.form = response.customer;
          this.$loading.finish();
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    handleSave() {
      this.$loading.start();
      if (this.isInsert) {
        this.store();
      } else {
        this.update();
      }
    },

    store() {
      this.$http
        .store("customer/customer", this.form)
        .then((response) => {
          this.processSaved(response.customer);
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    update() {
      this.$http
        .update("customer/customer", this.form.id, this.form)
        .then((response) => {
          console.log(response);
          this.processSaved();
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    processSaved(customer) {
      this.$store.commit("onboarding/customerStored");
      this.$emit("store", customer);
      this.$loading.finish();
      this.dialog = false;
    },
  },
};
</script>

<style scoped></style>
