<template>
  <app-select
    label="Grupo"
    v-bind="$attrs"
    :dense="dense"
    v-on="$listeners"
    :items="groups"
    :item-text="itemText"
    :item-value="itemValue"
  />
</template>

<script>
export default {
  props: {
    itemText: {
      default() {
        return (val) => val.name;
      },
    },

    itemValue: {
      default() {
        return (val) => val;
      },
    },

    dense: {
      default: true,
    },
  },

  data: () => ({
    groups: [],
  }),

  created() {
    this.select();
  },

  methods: {
    select() {
      this.$http.index("group/group").then((response) => {
        this.groups = response.groups;
      });
    },
  },
};
</script>
