<template>
  <div>
    <template v-if="$acl.isClinic()">
      <h3 class="font-weight-regular font-weight-regular mb-4">
        <app-icon left>badge</app-icon>
        Profissional Responsável
      </h3>
      <v-row>
        <v-col cols="12" md="6">
          <SelectProfessional ref="SelectProfessional" clearable v-model="form.professional" />
        </v-col>
      </v-row>
    </template>
    <h3 class="font-weight-regular mt-10 mb-4">
      <v-icon left>mdi-account-outline</v-icon>
      Informações
    </h3>
    <v-row>
      <v-col cols="12" md="6">
        <GroupSelect v-model="form.group" ref="GroupSelect" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <app-text-field v-model="form.name" label="Nome" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <app-whatsapp-field v-model="form.phone_wa" label="WhatsApp" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.video_call_link" label="Link de video chamada" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.email" label="Email" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <app-cpf-field v-model="form.cpf" label="CPF" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.rg" label="RG" />
      </v-col>
    </v-row>

    <h3 class="font-weight-regular mt-10 mb-4">
      <v-icon left>mdi-map-marker-radius-outline</v-icon>
      Endereço
    </h3>

    <v-row>
      <v-col cols="12" md="4">
        <app-cep-field v-model="form.main_address.postal_code" @address="setAddress($event)" />
      </v-col>
      <v-col cols="12" md="12">
        <app-text-field v-model="form.main_address.address" label="Endereço" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.main_address.number" label=" Número" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.main_address.district" label="Bairro" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8" md="6">
        <app-text-field v-model="form.main_address.city" label="Cidade" />
      </v-col>
      <v-col cols="4" md="6">
        <app-text-field v-model="form.main_address.state" label="Estado" />
      </v-col>
      <v-col cols="12">
        <app-text-field v-model="form.main_address.complement" label="Complemento" />
      </v-col>
    </v-row>

    <h3 class="font-weight-regular mt-10 mb-4">
      <v-icon left>mdi-cash</v-icon>
      Financeiro
    </h3>

    <v-row>
      <v-col cols="6" md="3">
        <app-number-field v-model="form.value_per_session" label="Valor por sessão" />
      </v-col>
      <v-col cols="6" md="3">
        <app-number-field :min="1" :max="31" v-model="form.collection_day" label="Dia de Cobrança" :precision="0" />
      </v-col>
      <v-col cols="12" md="6">
        <v-switch size="small" class="mt-2" :hide-details="true" v-model="form.send_billing_message"
          label="Enviar lembrete mensal de cobrança" />
      </v-col>
    </v-row>

    <v-row v-if="form.send_billing_message">
      <v-col>
        <!-- lower case on customer alias -->
        <v-alert color="primary" text>Atenção: Cobrança automática ativada. Mantenha os registros financeiros do seu {{
          customerAlias.toLowerCase() }} atualizados.</v-alert>
      </v-col>
    </v-row>

    <h3 class="font-weight-regular mt-10 mb-4">
      <v-icon left>mdi-account-cash-outline</v-icon>
      Responsável Financeiro
    </h3>
    <v-row>
      <v-col cols="12">
        <app-text-field v-model="form.financial_responsible_name" label="Nome" />
      </v-col>
      <v-col cols="12" md="6">
        <app-whatsapp-field v-model="form.financial_responsible_whatsapp" label="WhatsApp" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.financial_responsible_email" label="Email" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.financial_responsible_cpf" label="CPF" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.financial_responsible_rg" label="RG" />
      </v-col>
    </v-row>

    <h3 class="font-weight-regular mt-10 mb-4">
      <app-icon left>contact_emergency</app-icon>
      Contato de emergência
    </h3>
    <v-row>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.emergency_contact_name" label="Nome  " />
      </v-col>
      <v-col cols="12" md="6">
        <app-whatsapp-field v-model="form.emergency_contact_whatsapp" label="Whatsapp " />
      </v-col>
    </v-row>

    <h3 class="font-weight-regular mt-10 mb-4">
      <v-icon left>mdi-clipboard-edit-outline</v-icon>
      Dados Adicionais do
      {{ customerAlias }}
    </h3>
    <v-row>
      <v-col cols="5" md="6">
        <app-select v-model="form.genre" label="Gênero" dense :items="genreOptions" />
      </v-col>
      <v-col cols="7" md="6">
        <app-date-picker v-model="form.birth_date" label="Data de Nascimento" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.nationality" label="Naturalidade" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.scholarity" label="Escolaridade" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.occupation" label="Profissão" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.forwarding" label="Encaminhamento" />
      </v-col>
      <v-col cols="12" md="6">
        <app-text-field v-model="form.civil_status" label="Estado Civil" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea v-model="form.details" label="Observações..." auto-grow outlined flat hide-details />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-switch v-model="form.send_session_reminder" :true-value="1" :false-value="0" hide-details
          label="Ativar lembrete de sessão " />
      </v-col>
      <v-col class="pt-0">
        <v-switch v-model="form.status" true-value="active" false-value="inactive" label="Cadastro Ativo"
          hide-details />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GroupSelect from "@/components/group/ui/GroupSelect";
import { customerAlias } from "@/utils/customerAlias";
import SelectProfessional from "../../app/forms/SelectProfessional.vue";

export default {
  components: {
    GroupSelect,
    SelectProfessional,
  },

  props: {
    value: {},
  },

  data() {
    return {
      customerAlias: customerAlias(),

      genreOptions: [
        { value: "female", text: "Feminino " },
        { value: "male", text: "Masculino" },
        { value: "others", text: "Outros" },
      ],

      form: {
        video_call_link: null,
        send_billing_message: false,
      },
    };
  },

  computed: {
    company() {
      return this.$store.state.auth.company;
    },

    // phoneWaIsValidated() {
    //   return this.form.phone_wa_validated == 1 && this.company.has_chatbot == 1;
    // },

    // showSecurityCode() {
    //   if (this.company.has_chatbot == 0) {
    //     return false;
    //   }

    //   return !this.phoneWaIsValidated;
    // },
  },

  watch: {
    value() {
      this.setCustomer();
    },

    form: {
      handler() {
        this.$emit("input", this.form);
      },

      deep: true,
    },
  },

  created() {
    this.setCustomer();
  },

  methods: {
    setCustomer() {
      if (!this.value.main_address) {
        this.value.main_address = {};
      }
      this.form = this.value;
    },

    setAddress(address) {
      this.form.main_address = address;
    },

    copyCode() {
      // copy this.form.phone_wa_security_code to clipboard
      navigator.clipboard.writeText(this.company.phone_wa_security_code);

      this.$snackbar({
        message: "Código copiado para a área de transferência",
        color: "success",
        timeout: 1000,
        showClose: false,
      });

    }
  },
};
</script>

<style scoped lang="sass">
.v-icon
  color: black !important
</style>
